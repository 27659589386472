import type { GetAuctionResponse } from '@b-stock/auction-api-client'

export const useIsWithinBuyNowWindow = (auction: GetAuctionResponse) => {
  if (auction.buyNow?.enabled && auction.buyNow.endTime) {
    const now = new Date()
    const buyNowEndTime = new Date(auction.buyNow.endTime)
    return now < buyNowEndTime
  }
  return false
}
