import { faGavel } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInHours } from 'date-fns/differenceInHours'

import {
  FormattedMessage,
  CurrencyStringDollars,
  CurrencyStringCents,
} from '@b-stock/bstock-react'

import AuctionDeadline from '@components/AuctionDeadline'
import TextTransition from '@components/TextTransition'
import { useIsWithinBuyNowWindow } from '@helpers/useIsWithinBuyNowWindow'
import { BidStatus } from '@queries/listingSearchQuery/types'
import type { SearchResultWithAuction } from '@queries/listingSearchQuery/types'

import {
  Price,
  PriceContainer,
  BidAmountContainer,
  BidAmount,
  WinAt,
  BidsCountAndTime,
  TimeLeftContainer,
  BidsCount,
  VerticalDivider,
  Msrp,
  MsrpAmount,
} from './shared'
import PopcornInfo from '../PopcornInfo'
import type { UpdateAuctionDataFunction } from '../shared/useAuctionUpdates'
import useAuctionUpdates from '../shared/useAuctionUpdates'

type SearchResultAuctionSectionProps = {
  result: SearchResultWithAuction
  updateAuctionData: UpdateAuctionDataFunction
  onManualPollScheduled: (nextManualPoll: Date | null) => void
}

export const SearchResultAuctionSection = ({
  result,
  updateAuctionData,
  onManualPollScheduled,
}: SearchResultAuctionSectionProps) => {
  const { bidStatus = '', winningBidAmount = 0, bid } = result
  const hasBids = bidStatus !== BidStatus.NO_BID
  const won = bidStatus === BidStatus.WON
  const losing = bidStatus === BidStatus.LOSING
  const lost = bidStatus === BidStatus.LOST
  const endTimeLeft = differenceInHours(new Date(), new Date(result.endTime))
  const hot = endTimeLeft > 0 && endTimeLeft < 1
  const maxBidAmount = bid?.bidAmount || 0

  const isWithinBuyNowWindow = useIsWithinBuyNowWindow(result.auction)
  const buyNowPrice = result.auction.buyNow?.price
  const buyNowEndTime = result.auction.buyNow?.endTime

  useAuctionUpdates({
    listingId: result.listingId,
    auction: result.auction,
    updateAuctionData,
    onManualPollScheduled,
  })

  return (
    <div>
      {hasBids ? (
        <BidAmountContainer>
          <>
            {!losing && !lost ? (
              <WinAt>
                <FormattedMessage id="SearchResultCard.winningAt" />
              </WinAt>
            ) : null}

            <BidAmount $losing={losing || lost}>
              <FormattedMessage
                id={`SearchResultCard.${
                  losing || lost ? 'yourLastBidAmount' : 'maxBidAmount'
                }`}
                values={{ amount: maxBidAmount }}
              />
            </BidAmount>
          </>
        </BidAmountContainer>
      ) : null}
      {!hasBids && result.retailPrice ? (
        <Msrp>
          <FormattedMessage id="SearchResultCard.msrp" />
          <MsrpAmount>
            <CurrencyStringCents value={result.retailPrice} currency="USD" />
          </MsrpAmount>
        </Msrp>
      ) : null}

      <PriceContainer>
        <Price $won={won} $lost={lost} data-testid="price">
          <TextTransition transitionKey={winningBidAmount}>
            <CurrencyStringDollars
              value={
                isWithinBuyNowWindow && buyNowPrice
                  ? buyNowPrice
                  : winningBidAmount
              }
              currency="USD"
              omitDecimal
            />
          </TextTransition>
        </Price>
      </PriceContainer>

      <BidsCountAndTime>
        <TimeLeftContainer $hot={hot}>
          <AuctionDeadline
            formattedBidDeadline={null}
            deadline={
              isWithinBuyNowWindow && buyNowEndTime
                ? buyNowEndTime
                : result.endTime
            }
            distance
          />
        </TimeLeftContainer>

        {hot && <PopcornInfo amount={2} />}

        <VerticalDivider />

        <BidsCount>
          <FontAwesomeIcon icon={faGavel} />
          {result.numberOfBids || 0}
        </BidsCount>
      </BidsCountAndTime>
    </div>
  )
}
