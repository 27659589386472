import type { TransitionStatus } from 'react-transition-group'
import { SwitchTransition, Transition } from 'react-transition-group'
import { styled, css } from 'styled-components'

const transitionStyles: {
  [key in TransitionStatus]: ReturnType<typeof css>
} = {
  entering: css`
    opacity: 0;
    transform: translateY(100%);
    position: absolute;
  `,
  entered: css`
    opacity: 1;
    transform: translateY(0);
  `,
  exiting: css`
    opacity: 0;
    transform: translateY(-125%);
    position: absolute;
  `,
  exited: css`
    opacity: 0;
    transform: translateY(-125%);
    position: absolute;
  `,
  unmounted: [],
}

const TextContainer = styled.div<{
  $transitionState: TransitionStatus
  $inline?: boolean
}>`
  ${({ $inline }) =>
    $inline &&
    css`
      display: inline-block;
    `}

  transition: opacity 300ms ease, transform 300ms ease-in;

  ${({ $transitionState }) => transitionStyles[$transitionState]}
`

const TextTransition = ({
  transitionKey,
  children,
  inline,
}: {
  transitionKey: string | number
  children: React.ReactNode
  inline?: boolean
}) => {
  return (
    <SwitchTransition mode="in-out">
      <Transition
        key={transitionKey}
        timeout={300}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
      >
        {(state) => (
          <TextContainer $transitionState={state} $inline={inline}>
            {children}
          </TextContainer>
        )}
      </Transition>
    </SwitchTransition>
  )
}

export default TextTransition
