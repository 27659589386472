import getConfig from 'next/config'

let _pusherAppKey: string
let _pusherAppCluster: string

const getPusherCredentials = (): [string, { cluster: string }] => {
  if (!_pusherAppKey || !_pusherAppCluster) {
    const {
      publicRuntimeConfig: { pusherAppCluster, pusherAppKey },
    } = getConfig() as {
      publicRuntimeConfig: {
        pusherAppCluster?: string
        pusherAppKey?: string
      }
    }

    if (typeof pusherAppKey !== 'string') {
      throw new Error('No `pusherAppKey` declared in `publicRuntimeConfig`')
    }
    if (typeof pusherAppCluster !== 'string') {
      throw new Error('No `pusherAppCluster` declared in `publicRuntimeConfig`')
    }

    _pusherAppKey = pusherAppKey
    _pusherAppCluster = pusherAppCluster
  }

  return [
    _pusherAppKey,
    {
      cluster: _pusherAppCluster,
    },
  ]
}

export default getPusherCredentials
